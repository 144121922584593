






















import { defineComponent, computed } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'

import store from '@/store'

export default defineComponent({
  name: 'EmailSenderInput',
  components: { TextInput },

  setup() {
    const currentItem = computed(() => {
      return store.getters.communications.currentItem
    })

    const displayName = computed({
      get() {
        return store.getters.communications.messagePreview.displayName ?? ''
      },
      set(emailDisplayName: string) {
        store.commit.communications.setEmailDisplayName({ emailDisplayName })
      },
    })

    const replyTo = computed({
      get() {
        return store.getters.communications.messagePreview.replyTo ?? ''
      },
      set(emailReplyTo: string) {
        store.commit.communications.setEmailReplyTo({ emailReplyTo })
      },
    })

    return { displayName, replyTo, currentItem }
  },
})
