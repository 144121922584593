

















import { defineComponent, PropType, computed } from '@vue/composition-api'
//import { cloneDeep } from 'lodash'
import MemoInput from '@/elements/MemoInput.vue'
//import { isEmailValid } from '@/lib/support/utilities/email/emailUtil'

export default defineComponent({
  name: 'EmailListInput',
  components: { MemoInput },
  props: {
    value: { type: Array as PropType<string[]>, required: true },
    label: { type: String, required: false, default: '' },
    subText: { type: String, required: false },
    rows: { type: Number, required: false, default: 4 },
    required: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const emailList = computed({
      get() {
        return props.value.join(', ')
      },
      set(val: string) {
        const emails = val
          .trim()
          .replace(/(^,)|(,$)/g, '') // remove trailing comma
          .split(' ') // remove extra spaces
          .join('')
          .split(',') // convert to array
          .filter((email) => email.length > 0) // remove empty entries

        emit('input', emails)
      },
    })

    return { emailList }
  },
})
