




















import { defineComponent, computed, ref } from '@vue/composition-api'

import GospelVideoSelector from '@/components/Communication/GospelViedoSelector/GospelVideoSelector.vue'
import store from '@/store'
import { GospelVideoOptions } from '@/components/Communication/GospelViedoSelector/GospelVideoSelectorData'
import { useIsRunningChallenge } from '@/lib/common/programTypeHelpers'

import TextInput from '@/elements/TextInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'

export default defineComponent({
  name: 'EmailMessageInput',
  data() {
    return { GospelVideoOptions }
  },
  components: { TextInput, GospelVideoSelector, HtmlEditor },
  setup(props, { emit, root }) {
    const isRunningChallenge = useIsRunningChallenge()
    const showVideo = ref(false)
    const defaultVideoId = computed(() => {
      const noneId = GospelVideoOptions.get('None')?.id
      return noneId ?? ''
    })

    const subject = computed({
      get() {
        return store.getters.communications.messagePreview.emailSubject || ''
      },
      set(emailSubject: string) {
        store.commit.communications.setEmailSubject({ emailSubject })
      },
    })

    const body = computed({
      get() {
        return store.getters.communications.messagePreview.messageBody
      },
      set(messageBody: string) {
        store.commit.communications.setMessageBody({ messageBody })
      },
    })

    const vidId = ref(defaultVideoId.value)
    const videoId = computed({
      get() {
        return vidId.value
      },
      set(val: string) {
        vidId.value = val
        emit('gospelVideoChange', val)
        setGospelVideo()
      },
    })

    function toggle() {
      showVideo.value = !showVideo.value
    }

    function setGospelVideo() {
      const videoData = GospelVideoOptions.get(videoId.value)
      if (videoData) {
        subject.value = videoData.title == 'None' ? '' : videoData.title
        root.$nextTick(
          () => (body.value = `${videoData.emailText}<br/><a href="${videoData.url}">${videoData.url}</a>`)
        )
      }
    }

    return { subject, body, videoId, showVideo, toggle, isRunningChallenge }
  },
})
