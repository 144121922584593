






















































import { defineComponent, ref, computed, onUnmounted, onMounted } from '@vue/composition-api'
import EmailComposer from '@/views/Programs/Communications/vue/EmailComposer.vue'
import EmailPreviewer from '@/views/Programs/Communications/vue/EmailPreviewer.vue'
import Loading from '@/elements/Loading.vue'
import MessageConfirmationModal from '@/views/Programs/Communications/vue/MessageConfirmationModal.vue'
import CommunicationWarning from '@/views/Programs/Communications/vue/CommunicationWarning.vue'

import store from '@/store'
import { messageLogic } from '@/views/Programs/Communications/ext/MessageLogic'
import { mixin as clickaway } from 'vue-clickaway'
import DatePicker from '@/components/ModalDateTimePicker.vue'
export default defineComponent({
  name: 'Email',
  props: {},
  components: {
    EmailComposer,
    EmailPreviewer,
    Loading,
    MessageConfirmationModal,
    CommunicationWarning,
    DatePicker,
  },
  mixins: [clickaway],
  setup(props, ctx) {
    const logic = messageLogic()
    const emailComposer = ref<any | null>(null)

    // Add new functions for date picker handling
    const handleDatePicked = async (datePicked: Date) => {
      logic.sendCommunicationOn.value = datePicked
      logic.showScheduleSendModal.value = false
      await sendEmail()
    }

    const handleDateCancelled = () => {
      logic.showScheduleSendModal.value = false
      logic.sendCommunicationOn.value = null
    }

    const hasCredits = computed(() => {
      return (
        logic.providerInfo.value.emailMaxCredits < 0 ||
        logic.providerInfo.value.emailCredits < logic.providerInfo.value.emailMaxCredits
      )
    })

    const isAllowed = computed(() => {
      return logic.providerInfo.value.isEmailAllowed
    })

    async function sendEmail() {
      const isValid = await emailComposer.value.triggerValidation()
      if (isValid) {
        logic.send('sendemail')
      }
    }
    async function scheduleSend() {
      logic.showScheduleSendModal.value = (await emailComposer.value.triggerValidation()) as boolean
    }

    function loadTeams() {
      return new Promise<void>((resolve) => {
        ctx.root.$nextTick(async () => {
          try {
            logic.allTeams.value = await store.dispatch.teams.fetchTeams({
              upwId: logic.upwardLeagueID.value ?? '',
            })
          } finally {
            resolve()
          }
        })
      })
    }

    function setSenderDefaults() {
      store.commit.communications.setEmailDisplayName({
        emailDisplayName: store.getters.communications.leagueSettings?.emailDisplayName ?? '',
      })
      store.commit.communications.setEmailReplyTo({
        emailReplyTo: store.getters.communications.leagueSettings?.emailReplyTo ?? '',
      })
    }

    onMounted(async () => {
      await loadTeams()
      setSenderDefaults()
    })
    onUnmounted(() => store.commit.communications.clearMessageInformation())

    return {
      sendEmail,
      scheduleSend,
      emailComposer,
      hasCredits,
      isAllowed,
      handleDatePicked,
      handleDateCancelled,
      ...logic,
    }
  },
})
