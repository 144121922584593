

















































import { defineComponent, computed, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import RecipientListPreview from '@/components/Communication/RecipientListPreview.vue'
import InputLabel from '@/elements/InputLabel.vue'
import Loading from '@/elements/Loading.vue'
import ToClipboardBtn from '@/elements/ToClipboardBtn.vue'

import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'

import { Recipient } from '@/views/Programs/Communications/model/Recipients'

import { mergeAndCreateRecipients } from '@/views/Programs/Communications/ext/allRecipients'

import { useIsRunningChallenge } from '@/lib/common/programTypeHelpers'

import store from '@/store'
import MessageFilterModal from '@/views/Programs/Communications/vue/Filters/MessageFilterModal.vue'

export default defineComponent({
  name: 'EmailPreviewer',
  data() {
    return {
      MessageChannelTypes,
    }
  },
  components: { RecipientListPreview, InputLabel, Loading, ToClipboardBtn, MessageFilterModal },
  setup() {
    const showFilters = ref(false)
    const isRunningChallenge = useIsRunningChallenge()
    const count = computed(() => recipientEmails.value.length)

    const previewMessage = computed(() => store.getters.communications.messagePreview)
    const messagePreviewLoading = computed(() => store.getters.communications.messagePreviewLoading)

    function filterClick() {
      showFilters.value = true
    }

    function closeFilters() {
      showFilters.value = false
    }

    const recipients = computed(() => {
      return mergeAndCreateRecipients(cloneDeep(previewMessage.value))
    })

    const recipientEmails = computed(() => {
      return recipients.value.map((recipient: Recipient) => recipient.email)
    })

    const attachment1 = computed(() => store.getters.communications.attachment1)
    const attachment2 = computed(() => store.getters.communications.attachment2)

    function removeAttachment(n: number) {
      store.commit.communications.removeAttachment({ position: n })
    }
    return {
      count,
      recipients,
      recipientEmails,
      removeAttachment,
      isRunningChallenge,
      previewMessage,
      filterClick,
      showFilters,
      attachment1,
      attachment2,
      messagePreviewLoading,
      closeFilters,
    }
  },
})
