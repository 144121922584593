



























import { defineComponent, ref, computed } from '@vue/composition-api'

import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import EmailSenderInput from '@/views/Programs/Communications/vue/EmailSenderInput.vue'
import EmailRecipientInput from '@/views/Programs/Communications/vue/EmailRecipientInput.vue'
import EmailMessageInput from '@/views/Programs/Communications/vue/EmailMessageInput.vue'
import AttachmentInput from '@/views/Programs/Communications/vue/AttachmentInput.vue'
import InputlessStringValidator from '@/elements/InputlessStringValidator.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { Attachment } from '@/GeneratedTypes/Communication/Attachment'
import { getEmptyAttachment } from '@/lib/support/models/GeneratedTypes/Communication/Attachment'
import store from '@/store'
import { messageLogic } from '@/views/Programs/Communications/ext/MessageLogic'

export default defineComponent({
  name: 'EmailComposer',
  components: {
    VeeValidateForm,
    EmailSenderInput,
    EmailRecipientInput,
    AttachmentInput,
    EmailMessageInput,
    InputlessStringValidator,
    InputLabel,
  },
  setup() {
    const form = ref<any | null>(null)
    const { hasRecipients } = messageLogic()
    const attachment1 = computed({
      get() {
        return store.getters.communications.attachment1
      },
      set(v: Attachment) {
        store.commit.communications.setAttachment({ attachment: v, position: 1 })
      },
    })
    const attachment2 = computed({
      get() {
        return store.getters.communications.attachment2
      },
      set(v: Attachment) {
        store.commit.communications.setAttachment({ attachment: v, position: 2 })
      },
    })

    const showAttachments = ref(true)
    function gospelVideoChange(videoId: string) {
      showAttachments.value = videoId == 'None'
      if (!showAttachments.value) {
        clearAttachments()
      }
    }

    function clearAttachments() {
      attachment1.value = getEmptyAttachment()
      attachment2.value = getEmptyAttachment()
    }

    async function triggerValidation() {
      return await form.value.reportValidity()
    }

    return {
      form,
      attachment1,
      attachment2,
      hasRecipients,
      triggerValidation,
      showAttachments,
      gospelVideoChange,
    }
  },
})
