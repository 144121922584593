





























import { defineComponent, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import EmailListInput from '@/elements/EmailListInput.vue'
import RecipientGroupInput from '@/views/Programs/Communications/vue/RecipientGroupInput.vue'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
import MemoInput from '@/elements/MemoInput.vue'
import { useIsRunningChallenge } from '@/lib/common/programTypeHelpers'
import RecipientListManager from '@/components/Communication/RecipientListManager.vue'
import router from '@/router'
import Loading from '@/elements/Loading.vue'
import { messageLogic } from '@/views/Programs/Communications/ext/MessageLogic'

export default defineComponent({
  name: 'EmailRecipientInput',
  components: { EmailListInput, RecipientGroupInput, MemoInput, RecipientListManager, Loading },
  setup() {
    const isRunningChallenge = useIsRunningChallenge()
    const { loadRunningRecipients, loadingRecipients } = messageLogic()

    onMounted(() => {
      if (isRunningChallenge.value) {
        loadRunningRecipients(router.currentRoute.params.id)
      }
    })

    const additionalEmails = computed<string>({
      get() {
        return store.getters.communications.messagePreview.toDelimited
      },
      set(additionalRecipients: string) {
        //clean the string
        const toDelimited = additionalRecipients
          .trim()
          .replace(/(^,)|(,$)/g, '') //remove trailing comma
          .split(' ') //remove extra spaces
          .join('')

        //update the store
        store.commit.communications.setToDelimited({ toDelimited })
      },
    })

    return { additionalEmails, MessageChannelTypes, isRunningChallenge, loadingRecipients }
  },
})
